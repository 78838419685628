<template>
  <v-card rounded="lg" class="pa-3">
    <v-card-text>
      <div class="row">
        <div class="col-5 d-flex justify-center">
          <v-avatar size="180">
            <v-img :src="api+'/user/'+user.id+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true&ts='+rand"></v-img>
           
          </v-avatar>
        </div>
        <div class="col-7">
          <div class="row">
            <div class="col-10 d-flex align-center">
              <span class="custom-title">
                {{ user.firstname }} {{ user.name }}
              </span>
            </div>
            <div class="col-2 text-right">
              <v-btn v-if="$func.hasRight('user/update') || this.$store.state.auth.user.id === user.id" class="square" depressed text @click="$emit('edit')">
                <v-icon small color="primary">$pencil_d</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="py-3 d-table ml-auto mr-auto">
            <div class="mb-3" v-if="user.email">
              <v-icon color="primary" small class="mr-2">$at_d</v-icon>
              <a :href="'mailto:' + user.email">{{ user.email }}</a>
            </div>
            <div v-if='user.phones && user.phones.length > 0' class="adress mb-3">
                <v-icon color="primary" small class="mr-2">$phone_d</v-icon>
                <div>
                    <div v-for="(phone, index) in user.phones" :key="index">
                        <a :href="'tel:' + phone.number">{{ phone.number }}</a>
                    </div>
                </div>
            </div>
          </div>

          <v-divider></v-divider>
          <table class="pt-3 ml-auto mr-auto">
            <tr class="block" v-if="user.poste">
              <td class="text-right">
                <b>{{$t('job')}} : </b>
              </td>
              <td class="text-left">{{ user.poste }}</td>
            </tr>
            <tr class="block" v-if="user.univers && user.univers.length > 0">
              <td class="text-right">
                <b>{{$t('universes')}} : </b>
              </td>
              <td class="text-left">{{ user.univers.map(e => e.libelle).join(", ") }}</td>
            </tr>
            <tr class="block" v-if="user.social_raison">
              <td class="text-right ">
                <b>{{$t('society')}} : </b>
              </td>
              <td class="text-left">
                <span v-for="(social_raison, index) in user.social_raison">
                    <router-link v-if="social_raison.value && $func.hasRight('entity/get')" :to="{ name: 'EntityDashboard', params: { entity_id: social_raison.value }}">
                        {{ social_raison.text }}{{ index < user.social_raison.length - 1 ? ', ' : '' }}
                    </router-link>
                    <span v-else>{{ social_raison.text }}{{ index < user.social_raison.length - 1 ? ', ' : '' }}</span>
                </span>
              </td>
            </tr>
            <tr class="block" v-if="user.brands && user.brands.length > 0">
              <td class="text-right">
                <b>{{$t('brands')}} : </b>
              </td>
              <td class="text-left">{{ user.brands.map(e => e.libelle).join(", ") }}</td>
            </tr>
            <tr class="block" v-if="user.lang && user.lang.libelle">
              <td class="text-right">
                <b>{{$t('spokenLanguage')}} : </b>
              </td>
              <td class="text-left">{{ user.lang.libelle }}</td>
            </tr>
          </table>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "idComponent",
  props: ["user", "rand"],
  data() {
    return {
      api:this.$store.state.auth.apiUrl,
    };
  },
  computed: {
    getUserInitials() {
      var initials = this.user.firstname ? this.user.firstname.charAt(0) : "";
      initials += this.user.name ? this.user.name.charAt(0) : "";
      return initials;
    },
  },
};
</script>

<style lang="scss">
.custom-title {
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}
.adress {
  display: flex;
  .v-icon {
    margin-top: 3px;
  }
}
</style>
